import * as React from 'react'
import Layout from '../components/layout'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { graphql } from 'gatsby'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import Bibliography  from '../components/bibliography'
import * as IS from '../styles/index.module.css'

const IndexPage = ({ data }) => {
  let d = {}
  for(let node of data.allMdx.nodes) {
    d[node.frontmatter.title] = node
  }
  const siteMetadata = useSiteMetadata()

  return (
    <Layout className={IS.index}>
      <MDXRenderer m={siteMetadata} localImages={d.index.frontmatter.embeddedImagesLocal}>
        {d.index.body}
      </MDXRenderer>
      <hr />
      <MDXRenderer>
        {d.publications.body}
      </MDXRenderer>
      <Bibliography nosort={true} name="publications" className={IS.bibliography} />
    </Layout>
  )
}

export const q = graphql`
query indexQuery {
  allMdx(filter: {frontmatter: {title: {in: ["index", "publications"]}}}) {
    nodes {
      id
      body
      frontmatter {
        title
        date
				embeddedImagesLocal {
					childImageSharp {
						gatsbyImageData
					}
				}
      }
    }
  }
}`

export default IndexPage